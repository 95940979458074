import React from "react"
import { graphql } from "gatsby"
import BlogCard from "../components/BlogCard/BlogCard"
import BlogLayout from "../components/blogLayout"
import { setArchiveMonths } from "../utils"

const ArchivePageTemplate = ({ data, pageContext, location }) => {
  const { currentPage } = pageContext
  const { edges: posts } = data.allMarkdownRemark
  return (
    <BlogLayout location={location} seoTitle={setArchiveMonths(currentPage)}>
      <h3 className="current-page">{setArchiveMonths(currentPage)}</h3>
      {posts.map(({ node }) => (
        <BlogCard key={node.id} data={node} />
      ))}
    </BlogLayout>
  )
}

export default ArchivePageTemplate

export const ArchivePageQuery = graphql`
  query ArchivePage($currentPage: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { archive: { eq: $currentPage } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            category
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 940, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
            date(formatString: "DD-MM-YYYY")
          }
          id
          fields {
            slug
          }
        }
      }
    }
  }
`
